// Generated on Tue Dec 10 16:06:01 UTC 2024

export const environment = {
  production: false,
  envName: "dev",

  apiKeyGoogleMaps: "AIzaSyCxYTvKfyUN5CTRzAREsdCJGGy26e1sNio",
  gaTrackingId: "G-EGHMZ0SQWS",
  bucketS3: "sanofi-elefight-input-test",

  apiGatewayId: "wlvcwrgdd9",
  apiUrl: "https://test.elefight.com/v1",
  redirectUrl: "https://test.elefight.com/v1/qrcode/access",
  inputPath: "/public/",
  i18nPath: "https://sanofi-elefight-input-test.s3-eu-west-1.amazonaws.com/public/assets/i18n/",

  dataPath: "COUNTRY/FRANCE/2020/",
  csvFilename: "Real_Data_2020_France",

  identityPoolId: "eu-west-1:e569efcb-cc2b-456f-addd-939afb49e0bb",
  region: "eu-west-1",
  userPoolId: "eu-west-1_3LjdVk7nY",
  userPoolWebClientId: "2bjsb21ddntr4he8hn14e162hp",

  cognito_idp_endpoint: ""
};
